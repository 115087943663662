import React from "react"
import { IMG } from "../const"

export const TITLES = {
  testimonials: {
    title: "clients’ testimonials",
    strongText: (
      <>
        our clients often come to us for recommendations&nbsp;— this is a great
        indicator of our services
      </>
    ),
    uppercase: (
      <>
        Trusted
        <br /> partnership
      </>
    ),
    withIndent: false,
  },
  solutions: {
    title: (
      <>
        <strong>solutions</strong> that we provide:
      </>
    ),
    strongText: "we offer a wide range of digital services for HR industries",
    uppercase: "provide answers",
    withIndent: false,
  },
  integrations: {
    title: (
      <>
        list of <strong>integrations</strong>&nbsp;we can implement
      </>
    ),
    strongText:
      "we have extensive experience in integrations with talent workforce, payroll, performance and recruitment management systems",
    uppercase: "integrations",
    withIndent: true,
  },
  technology: {
    title: (
      <>
        LaSoft <br />
        <strong>one–stop shop</strong> <br />
        technology partner:
      </>
    ),
    strongText:
      "our clients receive a full range of technology services as a result of working with us",
    uppercase: (
      <>
        full range of <br />
        services
      </>
    ),
    withIndent: true,
  },
  case_studies: {
    title: (
      <>
        case studies we're <strong>proud&nbsp;of</strong>
      </>
    ),
    strongText:
      "we use a structured and tested approach to provide beautiful digital services for our partners",
    uppercase: (
      <>
        cases from 10+
        <br /> industries services
      </>
    ),
    withIndent: true,
  },
  colored: {
    title: (
      <>
        interested in <br />
        HR software <br />
        development?
      </>
    ),
    strongText: "request a consultation today",
    uppercase: "Project estimate",
    link: "#contact-form",
    linkClassName: "hr-estimate",
    imageDesktop: `${IMG.HR_TECH}/scheme-white.svg`,
    imageDesktopWidth: 940,
    imageDesktopHeight: 92,
    industry: "HRtech",
    withIndent: false,
  },
}

export const SERVICES = {
  text:
    "We are a tech company with experience in the HR field specifically. Now is the time to partner with us.",
  items: [
    {
      id: 1,
      title: "HR Tech Consulting",
      subTitle: "We also offer consulting services:",
      text: (
        <>
          <p>
            Creating cutting-edge solutions for our HR clients and partners for
            almost 10 years, we have gained significant industry technological
            expertise and are eager to share it.
          </p>
          <h4>Launch Adviser</h4>
          <p>
            Learn how much your HR or recruitment software will cost, how long
            it will take to develop it, what technologies to use, and how to
            integrate software into company infrastructure.
          </p>
          <h4>Product strategy development</h4>
          <p>
            Learn unique, innovative solutions for efficiently fulfilling the
            most critical business targets and user' needs.
          </p>
          <h4>Digital business design</h4>
          <p>Learn how to upgrade business with HR or recruitment software.</p>
        </>
      ),
      link: "",
    },
    {
      id: 2,
      title: "Software for HR & Recruitment",
      text: (
        <>
          <p>
            Through your partnership with an HR software development company,
            get unique products that solve your specific goal. Any web, mobile,
            and desktop:
          </p>
          <ul>
            <li>Custom HR and recruitment management software;</li>
            <li>HR and payroll software;</li>
            <li>
              Low-code software for small business HR and recruitment software;
            </li>
            <li>Medium and corporate company HR or recruiting software;</li>
            <li>Onboarding HR software.</li>
          </ul>
        </>
      ),
      link: "",
    },
    {
      id: 3,
      title: "Product design",
      text: (
        <>
          <p>
            Users expect the HR or Recruitment product will be intuitive,
            user-friendly, and include only the necessary features. Achieve this
            goal with the help of design services:
          </p>
          <ul>
            <li>Product discovery;</li>
            <li>Prototyping;</li>
            <li>UX/UI design;</li>
            <li>Advanced data visualization.</li>
          </ul>
        </>
      ),
      link: "",
    },
    {
      id: 4,
      title: "The automation and scaling",
      text:
        "Any small consulting company has 1 or 2 founders that collect the main knowledge and experience. It is not easy to scale. As a team, we build software that helps automate a company's workflows, deliver more to the client in less time, train newcomers, and offer better, more convenient services.",
      link: "",
    },
    {
      id: 5,
      title: "A new digital HR product",
      text:
        "The post-Covid, remote work phenomenon has opened up new opportunities to create services and products in the HR field. Our team can design a system, create a prototype, and help you to test your ideas with real users.",
      link: "",
    },
  ],
}

export const SOFTWARE_DEVELOPMENT_SERVICES = [
  {
    id: 1,
    title: "Full circle software development",
    text:
      "We can plan, design, and develop your product as a solo vendor. We take care of all aspects of software development, including product design and architecture, UI/UX design, Web App development, Quality Assurance, project management, and project support.",
  },
  {
    id: 2,
    title: "World-class Product Design expertise",
    text:
      "Are you wondering if you should create a new design or buy a template? Many of our customers have this dilemma when they decide to build a webpage. This option is suitable for testing your ideas.",
  },
  {
    id: 3,
    title: "Cloud Infrastructure deployment and support",
    text:
      "We can create or develop cloud infrastructure for business. Want to remove your business from the cloud? We’ll take care of it. Want to optimize your finances in DevOps? We will find the cheapest solution without losing the quality of your business!",
  },
  {
    id: 4,
    title: "Expertise in data analytics and custom dashboard development",
    text:
      "We have extensive knowledge in developing high-load dashboards with modern infographics. Our teammates worked in world-famous companies like HP, Pearson, Adidas, Boss, Calvin Klein, and s.Oliver.",
  },
  {
    id: 5,
    title: "Mobile development",
    text:
      "Android or iOS? You don’t have to choose. We make solutions for all operating systems.",
  },
]

export const INTEGRATIONS = [
  {
    name: "all in one",
    items: [
      { name: "Bamboo HR", icon: "bamboohr.svg" },
      { name: "CATSone API", icon: "catsone.svg" },
      { name: "Personio API", icon: "personio.svg" },
      { name: "Workforce Now", icon: "adp.svg" },
      { name: "UKG Pro", icon: "ukg.svg" },
      { name: "Workday", icon: "workday.svg" },
      { name: "Gusto", icon: "gusto.svg" },
      { name: "Jazz API", icon: "jazzhr-white.svg" },
      { name: "HiBob", icon: "hibob.svg" },
      { name: "Paylocity", icon: "paylocity.svg" },
      { name: "Ceridian", icon: "ceridian.svg" },
    ],
  },
  {
    name: "payroll",
    items: [
      { name: "Paycor", icon: "paycor.svg" },
      { name: "Paychex API", icon: "paychex.svg" },
      { name: "Finch API", icon: "finch.svg" },
      { name: "OnPay", icon: "onpay.svg" },
    ],
  },
]

export const TESTIMONIALS = [
  {
    id: "Kapil-Gupta",
    name: "Kapil Gupta",
    position: "CEO, Founder",
    text:
      "We’ve been working with Lasoft for almost two years, and it’s been a fantastic experience. They have a strong work ethic, deliver high-quality results, and communicate clearly and responsively. What stands out is their commitment to understanding our needs and going above and beyond to help us. Lasoft has been an amazing partner in building our platform.",
    image: "Kapil-Gupta",
  },
  {
    id: "Casadesk",
    name: "Job Breur",
    position: "Chief Starting Officer",
    text:
      "LaSoft provided me with a very professional team that delivered me good results during a first project. Because of this good experience, I recently did not hesitate to select LaSoft for a new project. They provide all necessary experience, including UX/UI, programming, testing, project management, and cloud deployment. I enjoy working with guys and trust we've started a long-term relationship.",
    image: "Job-Breur",
  },
  {
    id: "Kamsa",
    name: "Matt Harbes",
    position: "CTO of Kamsa",
    text:
      "We've worked with LaSoft for several years and are continually impressed. Their passion and dedication to Kamsa is second to none, and they are always looking for new ways to improve and ensure the success of our team. We couldn't ask for a better partnership.",
    image: "Matt-Harbes",
  },
  {
    id: "Minutes Depot",
    name: "Louis Bedard",
    position: "CEO at Minutes Depot",
    text:
      "While working on a project, all team really care about our project and our needs. We recommend them (LaSoft) without a doubt to anyone looking for high-quality and professional programming for a reasonable price.",
    image: "Louis-Bedard",
  },
]
